<template>
  <div class="main">
    <div id="activity"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  mounted() {
    this.initActivity()
  },
  methods: {
    initActivity() {
      let inspect = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      let purchase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var activity = echarts.init(document.getElementById('activity'))
      let option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            console.log(params)
            let outerStyle =
              'background: rgba(4,9,41,0.95);box-shadow: inset 0px 0px 1rem 0px rgba(0,122,255,0.81);border-radius: 0.08rem;border: 0.04rem solid #0C87FF;padding:0.29rem;font-size: 0.66rem;'
            // debugger
            // var color = params.color //图例颜色
            var htmlStr = `<div style= 'background: rgba(4,9,41,0.95);box-shadow: inset 0px 0px 1rem 0px rgba(0,122,255,0.81);border-radius: 0.08rem;border: 0.04rem solid #0C87FF;padding:0.29rem;font-size: 0.66rem;'>`
            htmlStr += params[0].name + '月' + '<br/>' //x轴的名称
            //为了保证和原来的效果一样，这里自己实现了一个点的效果
            htmlStr += '<span ></span>'
            //添加一个汉字，这里你可以格式你的数字或者自定义文本内容
            htmlStr += `<div style="width:0.41rem;height:0.41rem;background:#17BC7B;display:inline-block;border-radius: 50%;"></div><span style="color: #8EB8E6;font-size: 0.5rem;font-weight: 500;line-height: 0.5rem;padding-left:0.2rem;">体检人数</span><span style="color:#FD8429;padding-left:0.2rem;font-size: 0.5rem;font-weight: 500;">${params[0].value}</span><br/>`

            htmlStr += `<div style="width:0.41rem;height:0.41rem;background:#FF831B;display:inline-block;border-radius: 50%;"></div><span style="color: #8EB8E6;font-size: 0.5rem;font-weight: 500;line-height: 0.5rem;padding-left:0.2rem;">自助付费购买健康管理服务人数</span><span style="color:#FD8429;padding-left:0.2rem;font-size: 0.5rem;font-weight: 500;">${params[1].value}</span><br/>`
            htmlStr += '</div>'
            return htmlStr
          },
        },
        color: ['red', 'yellow'],
        legend: {
          icon: 'roundRect',
          itemWidth: 13, //图形宽度
          itemHeight: 8, //图形高度
          data: ['体检人数', '自助付费购买健康管理服务人数'],
          right: '0',
          textStyle: {
            color: '#8EB8E6',
          },
        },
        grid: {
          top: '25%',
          bottom: '10%',
          right: '8%',
        },
        xAxis: {
          name: '月份',
          type: 'category',
          data: [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
          ],
          axisLine: {
            lineStyle: {
              color: '#8EB8E6',
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: '人数',
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#8EB8E6',
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#222F59',
            },
          },
        },
        series: [
          {
            name: '体检人数',
            data: inspect,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: '#69FF3A', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#1BE4FF', // 100% 处的颜色
                  },
                ],
              },
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#69FF3A',
                  },
                  {
                    offset: 1,
                    color: '#1BE4FF ',
                  },
                ]),
              },
              emphasis: {
                color: 'rgb(0,196,132)',
                borderColor: 'rgba(0,196,132,0.2)',
                extraCssText: 'box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);',
                borderWidth: 10,
              },
            },
          },
          {
            name: '自助付费购买健康管理服务人数',
            data: purchase,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: '#FF6F3A', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFF31B', // 100% 处的颜色
                  },
                ],
              },
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#FF6F3A',
                  },
                  {
                    offset: 1,
                    color: '#FFF31B',
                  },
                ]),
              },
              emphasis: {
                color: 'rgb(99,250,235)',
                borderColor: 'rgba(99,250,235,0.2)',
                extraCssText: 'box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);',
                borderWidth: 10,
              },
            },
          },
        ],
      }
      activity.setOption(option)
      window.addEventListener('resize', function () {
        activity.resize()
      })
    },
  },
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 10.95rem;
  background: url('../../../../image/twoThreeImgs/left/activityBg2.png');
  overflow: hidden;
  margin-top: 0.3rem;
}
#activity {
  /* margin-top: 38px; */
  margin: 1.58rem 0.41rem 0.41rem;
  height: calc(11.08rem - 2.41rem);
  width: calc(100% - 0.83rem);
}
</style>
